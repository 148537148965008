@import "assets/styles/variables";

body {
  margin: 0;
  background-color: $white;
  box-sizing: border-box;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFFFFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #E6E6E6;
  border-radius: 100vw;
}

.scroller:hover {
  --fix: ; /* This custom property invalidates styles on hover, thereby enforcing a style recomputation. This is needed to work around a bug in Safari. */
}

.ant-form-item {
  margin: 0;
}

.ant-form-item .ant-form-item-control-input {
  min-height: unset;
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("../fonts/DMSans/DMSans_Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("../fonts/DMSans/DMSans_Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-SemiBold";
  src: url("../fonts/DMSans/DMSans_SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("../fonts/DMSans/DMSans_Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-ExtraBold";
  src: url("../fonts/DMSans/DMSans_ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Jost-Bold";
  src: url("../fonts/Jost/Jost_Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Jost-ExtraBold";
  src: url("../fonts/Jost/Jost_ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Futura-Bold";
  src: url("../fonts/Futura/Futura_BdCn_BT_Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Futura-Medium";
  src: url("../fonts/Futura/Futura_MdCn_BT_Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Futura-Light";
  src: url("../fonts/Futura/Futura_LtCn_BT_Light.ttf") format("truetype");
}
@font-face {
  font-family: "DMSans-ExtraLight";
  src: url("../fonts/DMSans/DMSans_ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "DMSans-Thin";
  src: url("../fonts/DMSans/DMSans_Thin.ttf") format("truetype");
}
@font-face {
  font-family: "DMSans-Light";
  src: url("../fonts/DMSans/DMSans_Light.ttf") format("truetype");
}
.services {
  .top_banner {
    position: relative;
    padding-left: 40px;
    max-height: 618px;
    height: 100%;

    .top_banner_background {
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
      height: 490px;
      background-color: #331DAD;
      display: flex;

      .title {
        font-family: Futura-Bold, sans-serif;
        font-size: 100px;
        line-height: 119.87px;
        padding-left: 102px;
        padding-bottom: 40px;
        margin-top: auto;
      }
    }
    .image {
      max-height: 618px;
    }

  }

  .services_container {
    max-width: 86.1%;
    margin: 22px auto 100px;
    //padding: 0 16px;
  }
}

@media screen and (max-width: 1200px) {
  .services {
    .top_banner {
      padding-left: 26px;
      max-height: 348px;

      .top_banner_background {
        height: 273px;

        .title {
          font-size: 60px;
          line-height: 71.92px;
          padding-left: 65px;
          padding-bottom: 22px;
        }
      }

      .image {
        max-height: 348px;
      }
    }

    .services_container {
      max-width: 100%;
      padding: 0 40px;
      margin: 0 0 40px;
    }
  }
}

@media screen and (max-width: 900px) {
  .services {
    .top_banner {
      padding-left: 21px;

      .top_banner_background {

        .title {
          padding-left: 62px;
          padding-bottom: 20px;
        }
      }

    }
  }
}

@media screen and (max-width: 450px) {
  .services {
    .top_banner {
      padding-left: 10px;
      max-height: 323px;

      .top_banner_background {
        .title {
          font-size: 40px;
          line-height: 47.95px;
          padding-left: 40px;
        }
      }

      .image {
        max-height: 323px;
      }
    }

    .services_container {
      padding: 0 16px;
      margin: 0 0 24px;
    }
  }
}
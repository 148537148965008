@import "assets/styles/variables";

.home {
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
}

.home_title_section {
  padding: 0 10px;
  flex: 0 0 auto;
}

.home_title {
  font-size: calc(100vw / 4.64516129032);
  line-height: calc(100vw / 4.64516129032 / 1.23270511872);
  font-family: $font-jost-bold;
  margin: 0 auto;
  width: max-content;
  display: block;
  -webkit-user-select: none;
  user-select: none;
}

.blur_section {
  padding: 0 60px 40px;
  flex: 1;

  & > div {
    height: 100%;
    flex-direction: column;
  }

  .blur_description {
    flex: 0 0 auto;
    line-height: 20.83px;
    padding-left: 40px;
  }

  .blur_content {
    background-color: #F1FAFF;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .blur_square {
      background-color: #15A6FF;
      transform: rotate(-60deg);
      flex-grow: 0;
      height: 60%;
      aspect-ratio: 1 / 1;
      position: absolute;
    }

    .blur_content_box {
      position: absolute;
      bottom: 0;
      height: 54%;
      display: flex;
      justify-content: left;
      max-width: 100%;
      width: 100%;
    }

    .blur_part {
      position: absolute;
      bottom: 0;
      height: 60%;
      width: 100%;
    }

    .blur {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.005) 0%, rgba(29, 29, 29, 0.05) 72.5%, rgba(255, 255, 255, 0.005) 100%);
      backdrop-filter: blur(4px);
    }

    .content_text {
      color: #FFFFFF;
      font-family: $main-font-extra-bold;
      font-size: 38px;
      //font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
      line-height: 40px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }


  .blur_contact {
    flex: 0 0 auto;

    .contact {
      line-height: 20.83px;

      &:hover {
        color: #331DAD;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .blur_section {
    .blur_content {
      .content_text {
        font-size: 33px;
        line-height: 36.46px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    height: calc(100vh - 72px);
  }
  .home_title {
    font-family: $font-jost-extra-bold;
  }
  .home_title_section {
    margin-top: 12px;
  }
  .blur_section {
    padding: 0 32px 32px;

    .blur_description {
      padding-left: 0;

    }

    .blur_content {
      .blur_square {
        width: 240px;
        height: 240px;
      }

      .content_text {
        font-size: 28px;
        line-height: 36.46px;
      }
    }
  }
}

@media screen and (min-width: 451px) {
  .blur_section {
    .blur_square {
      animation: blurSquareMoveBack 900ms ease 0ms 1 forwards;
    }

    .blur_square:hover {
      animation: blurSquare 900ms ease 0ms 1 forwards;
    }

    .blur_square:hover + .blur_content_box .content_text {
      animation: blurText 900ms ease 0ms 1 forwards;
    }

    .blur_content {
      .content_text {
        animation: blurTextMoveBack 900ms ease 0ms 1 forwards;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .home {
    height: calc(100dvh - 72px);
  }
  .home_title_section {
    padding: 0 6px;
    margin-top: 38px;
  }
  .blur_section {
    padding: 0 0 38px;

    .blur_description {
      line-height: 18.23px;
      padding-left: 12px;
    }

    .blur_content {
      padding: 0 12px;

      .blur_square {
        width: 146px;
        height: 146px;
        animation: blurSquareMoveBackMobile 900ms ease 0ms 1 forwards;
      }

      .blur_content_box {
        height: 52%;
      }

      .content_text {
        font-size: 20px;
        line-height: 26.04px;
        animation: blurTextMoveBackMobile 900ms ease 0ms 1 forwards;

      }
    }

    .blur_contact {
      padding: 0 12px;

      .contact {
        line-height: 18.23px;
        font-size: 14px;
      }
    }

    .blur_square:hover {
      animation: blurSquareMobile 900ms ease 0ms 1 forwards;
    }

    .blur_square:hover + .blur_content_box .content_text {
      animation: blurTextMobile 900ms ease 0ms 1 forwards;
    }
  }
}

@media screen and (max-width: 400px) {
  .blur_section {
    .blur_description {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 330px) {
  .blur_section {

    .blur_description {
      font-size: 12px;
    }
  }
}

@media screen and (width: 375px) {
  .home_title {
    font-size: 82px;
    line-height: 80px;
  }
}

@media screen and (width: 768px) {
  .home_title {
    font-size: 160px;
    line-height: 144px;
  }
}

@media screen and (width: 1280px) {
  .home_title {
    font-size: 273px;
    line-height: 220.83px;
  }
  .blur_section {
    .blur_content {
      .blur_square {
        width: 262px;
        height: 262px;
      }

      .content_text {
        font-size: 40px;
        line-height: 52.08px;
      }
    }
  }
}

@media screen and (width: 1366px) {
  .home_title {
    font-size: 293px;
    line-height: 238.2px;
  }
  .blur_section {
    .blur_content {
      .blur_square {
        width: 165px;
        height: 165px;
      }

      .content_text {
        font-size: 26px;
        line-height: 33.85px;
      }
    }
  }
}

@media screen and (width: 1440px) {
  .home_title {
    font-size: 310.82px;
    line-height: 252.54px;
  }
  .blur_section {
    .blur_content {
      .blur_square {
        width: 222px;
        height: 222px;
      }

      .content_text {
        font-size: 32px;
        line-height: 41.66px;
      }
    }
  }
}

@media screen and (width: 1600px) {
  .home_title {
    font-size: 348.35px;
    line-height: 283.24px;
  }
  .blur_section {
    .blur_content {
      .blur_square {
        width: 226px;
        height: 226px;
      }

      .content_text {
        font-size: 32px;
        line-height: 41.66px;
      }
    }
  }
}

@media screen and (width: 1920px) {
  .home_title {
    font-size: 424px;
    line-height: 343.81px;
  }
  .blur_section {
    .blur_content {
      .blur_square {
        width: 262px;
        height: 262px;
      }

      .content_text {
        font-size: 38px;
        line-height: 49.48px;
      }
    }
  }
}

@keyframes blurSquare {
  from {
    transform: translateY(0) rotate(-60deg);
  }
  to {
    transform: translateY(-33px) rotate(-60deg);
  }
}

@keyframes blurText {
  from {
    transform: translateY(0) translateX(-50%);
  }
  to {
    transform: translateY(-33px) translateX(-50%);
  }
}

@keyframes blurSquareMoveBack {
  from {
    transform: translateY(-33px) rotate(-60deg);
  }
  to {
    transform: translateY(0) rotate(-60deg);
  }
}

@keyframes blurTextMoveBack {
  from {
    transform: translateY(-33px) translateX(-50%);
  }
  to {
    transform: translateY(0) translateX(-50%);
  }
}

@keyframes blurSquareMobile {
  from {
    transform: translateY(0) rotate(-60deg);
  }
  to {
    transform: translateY(-20px) rotate(-60deg);
  }
}

@keyframes blurTextMobile {
  from {
    transform: translateY(0) translateX(-50%);
  }
  to {
    transform: translateY(-20px) translateX(-50%);
  }
}

@keyframes blurSquareMoveBackMobile {
  from {
    transform: translateY(-20px) rotate(-60deg);
  }
  to {
    transform: translateY(0) rotate(-60deg);
  }
}

@keyframes blurTextMoveBackMobile {
  from {
    transform: translateY(-20px) translateX(-50%);
  }
  to {
    transform: translateY(0) translateX(-50%);
  }
}
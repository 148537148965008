@import "assets/styles/variables";

.form_page_container {
  padding: 48px 60px 80px;
  flex-direction: column;
  min-height: 808px;

  .title_section {
    flex: 0 0 auto;

    .title_container {
      max-width: 88%;

      .title {
        font-family: $font-futura-bold;
        font-size: 80px;
        line-height: 95.9px;
        color: #140D0B;
        white-space: nowrap;
      }

      .info_container {
        max-width: 394px;

        .info {
          font-family: $font-futura-light;
          font-size: 20px;
          line-height: 24px;
          color: #140D0B;
        }
      }

    }
  }

  .main_section {
    flex: 1;
    max-height: 560px;

    .animation_form_container {
      gap: 32px;
      height: 100%;

      .left_banner {
        width: 100%;
        height: 100%;

        .animation_container {
          position: relative;
          background-color: #F1FAFF;
          height: 100%;

          .animation_content {
            background-color: #15A6FF;
            width: calc(100% - 120px);
            height: calc(100% - 120px);
            position: absolute;
            left: 60px;
            top: 60px;

            .animation_content_text {
              position: absolute;
              left: 50%;
              top: calc(50%);
              transform: translate(-50%, -50%);
              font-family: $font-futura-bold;
              font-size: 32px;
              line-height: 38.36px;
              color: #FFFFFF;
            }
          }

          .blur_column_container {
            position: absolute;
            bottom: 0;
            width: 100%;
            //max-height: 80%;
            height: 100%;

            .blur_column {
              margin-top: auto;
              backdrop-filter: blur(4px);
              //background-color: red;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.005) 0%, rgba(29, 29, 29, 0.05) 72.5%, rgba(255, 255, 255, 0.005) 100%);
            }
          }
        }
      }

      .right_banner {
        height: max-content;
        border: 1px solid #140D0B;
        padding: 40px;

        .checkbox_container {
          position: relative;
        }

        .error {
          position: absolute;
          top: calc(100% + 4px);
          gap: 6px;

          .error_text {
            color: #F5222D;
          }
        }

        .form_title {
          font-family: $font-futura-bold;
          font-size: 32px;
          line-height: 38.36px;
          color: #140D0B;
          margin-bottom: 20px;
        }

        .form_fields {
          gap: 32px;

          & > div {
            flex: 1 1 0;
          }
        }

      }
    }
  }

  .spin {
    svg {
      fill: #FFFFFF;
    }

  }
}
:where(.css-dev-only-do-not-override-i9mq4t).ant-spin-nested-loading .ant-spin-blur:after {
  opacity: 0;
}
@media screen and (min-width: 1700px) {
  .form_page_container {
    padding: 48px 200px 80px;
  }
}

@media screen and (max-width: 1365px) and (min-width: 1201px) {
  .form_page_container {
    .title_section {
      .title_container {
        .title {
          font-size: 70px;
        }

        .info_container {
          .info {
            font-size: 17px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 1025px) {
  .form_page_container {
    .title_section {
      .title_container {
        .title {
          font-size: 56px;
        }

        .info_container {
          .info {
            font-size: 15px;
          }
        }
      }
    }

    .main_section {
      .animation_form_container {

        .right_banner {
          padding: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .form_page_container {
    padding: 20px 40px 70px;
    gap: 16px;
    height: unset;

    .title_section {
      .title_container {
        max-width: 100%;

        .title {
          font-size: 9.5vw;
          line-height: 88.71px;
        }

        .info_container {
          max-width: max-content;

          .info {
            font-size: 16px;
            line-height: 20px;
          }
        }

      }
    }

    .main_section {
      flex: 1;
      max-height: unset;

      .animation_form_container {
        gap: 24px;

        .left_banner {
          height: 434px;

          .animation_container {

            .animation_content {
              width: calc(100% - 64px);
              height: calc(100% - 64px);
              left: 32px;
              top: 32px;
            }
          }
        }

        .right_banner {
          padding: 32px;
        }
      }
    }


  }
}

@media screen and (width: 768px) {
  .form_page_container {

    .title_section {
      .title_container {

        .title {
          font-size: 74px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .form_page_container {
    padding: 12px 16px 64px;
    gap: 16px;
    height: unset;

    .title_section {
      .title_container {
        max-width: 100%;

        .title {
          line-height: 43.15px;
        }

        .info_container {
          max-width: max-content;

          .info {
            font-size: 16px;
            line-height: 20px;
          }
        }

      }
    }

    .main_section {

      .animation_form_container {
        gap: 16px;

        .left_banner {
          height: 343px;
        }

        .right_banner {
          padding: 24px;

          .form_title {
            font-size: 20px;
            line-height: 23.97px;
          }

          .form_fields {
            & > div {
              flex: 1 1 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (width: 375px) {
  .form_page_container {

    .title_section {
      .title_container {

        .title {
          font-size: 36px;
        }
      }
    }
  }
}

@import "assets/styles/variables";

.form_submitted {
  height: 100%;

  .content {
    margin: auto;
    max-width: 312px;
    width: 100%;
    height: max-content;
  }

  .bottom_container {
    margin-top: 12px;
  }
}

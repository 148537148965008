.job_details {
  .square {
    width: 6px;
    height: 6px;
    background: #15A6FF;
  }
  .title {
    font-size: 22px;
    line-height: 28.64px;
  }
  .detail {
    font-size: 18px;
    line-height: 23.44px;
  }
}

@media screen and (max-width: 450px){
  .job_details {
    .title {
      font-size: 20px;
      line-height: 26.04px;
    }
    .detail {
      font-size: 16px;
      line-height: 20.83px;
    }
  }

}

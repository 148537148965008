@import "assets/styles/variables";

.nav {
  height: calc(100vh - 80px);
  padding: 60px;

  .nav_container {
    display: flex;
    flex-direction: row;
    height: max-content;
  }

  .contact {
    font-family: $font-futura-light;
    &:hover {
      color: #331DAD;
    }
  }
}

@media screen and (max-width: 1024px) {
  .nav {
    height: calc(100vh - 72px);
    padding: 28px 40px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;

    .contact_info {
      justify-content: flex-start;
    }

    .nav_container {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 450px) {
  .nav {
    height: calc(100dvh - 72px);
    padding: 30px 32px 38px;
  }
}
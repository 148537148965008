.collapse {
  padding: 20px 0;
  border-bottom: 1px solid #D5D5D5;

  .label_container {
    cursor: pointer;

    .label {
      line-height: 21.6px;
    }

    &:hover {
      .label {
        color: #331DAD;
      }

      svg {
        path {
          fill: #331DAD;
        }
      }
    }

  }

  .info {
    font-size: 18px;
    line-height: 23.44px;
    font-family: DMSans-Light, sans-serif;
  }

}

@media screen and (max-width: 1200px) {
  .collapse {
    padding: 16px 0;

    .label_container {
      .label {
        font-size: 16px;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .info {
      font-size: 14px;
    }
  }

}


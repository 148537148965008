@import "assets/styles/variables";

.credo {
  background-color: #331DAD;
  padding: 10px 140px;
  position: relative;
  align-items: center;

  .img_container {
    position: absolute;
    bottom: 0;
    left: 477px;
    opacity: 0.5;
  }

  .title {
    color: #15A6FF;
    font-family: $font-futura-medium;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 12px;
    display: inline-block;
  }

  .description, .secondary_text {
    font-family: $main-font-medium;
    font-size: 60px;
    font-weight: 500;
    line-height: 78.12px;
    color: #ffffff;
  }

  .secondary_text {
    font-family: $main-font-thin;
    font-weight: 59;
    line-height: 71px;
  }

}

@media screen and (max-width: 1050px) {
  .credo {
    padding:10px 87px 10px 115px;
    .img_container {
      display: none;
    }
    .title {
      font-size: 30px;
      line-height: 36px;
    }
    .description, .secondary_text {
      font-size: 56px;
      line-height: 72px;
    }
    .secondary_text {
      line-height: 66px;

    }
  }
}

@media screen and (max-width: 768px) {
  .credo {
    padding: 10px 73px;
    .title {
      font-size: 24px;
      line-height: 28px;
    }
    .description, .secondary_text {
      font-size: 40px;
      line-height: 52px;
    }
    .secondary_text {
      line-height: 47px;

    }
  }
}

@media screen and (max-width: 500px) {
  .credo {
    padding: 10px 29px;
    .title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 7px;
    }
    .description, .secondary_text {
      font-size: 26px;
      line-height: 33px;
    }
    .secondary_text {
      line-height: 31px;

    }
  }
}

@media screen and (max-width: 375px) {
  .credo {
    padding: 10px 28px;
    .title {
      font-size: 18px;
      line-height: 21px;
    }
    .description, .secondary_text {
      font-size: 24px;
      line-height: 31px;
    }
    .secondary_text {
      line-height: 28px;

    }
  }
}
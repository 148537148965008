.personal_card {
  //max-width: 343px;
  max-height: 343px;
  min-height: max-content;
  width: 100%;
  height: 100%;
  position: relative;

  .bottom_box {
    flex-direction: column;
    position: absolute;
    bottom: 0;
    background: rgba(26, 26, 26, 0.5);
    padding: 6px 12px;
    width: 100%;

    .name {
      font-family: Futura-Bold, sans-serif;
      font-size: 20px;
      line-height: 23.97px;
      display: block;
    }

  }

  .link {
    font-size: 19px;
    line-height: 28px;
  }

  .special_title {
    font-family: Futura-Light, sans-serif;
  }
}

.title {
  padding: 34px;
}

.quotation_marks {
  margin: auto;
  position: relative;

  .left_quotation_mark {
    position: absolute;
    right: calc(100% + 2px);
    top: calc(100% - 9px);
  }
}

@media screen and (max-width: 1024px) {
  .personal_card {
    .bottom_box {
      .name {
        font-size: 16px;
        line-height: 20px;
      }
      .position {
        font-size: 12px;
        font-size: 12px;
        line-height: 16px;
      }

    }
    .special_title {
      font-size: 17.36px;
      line-height: 24.13px;
    }
  }
  .title {
    padding: 30px;
    .title_text {
      font-size: 17.09px;
      line-height: 24.13px;
    }
    .link {
      font-size: 16.09px;
      line-height: 24.13px;
    }
  }
}

@media screen and (max-width: 767px) {
  .personal_card {
    .bottom_box {
      .name {
        font-size: 16px;
        line-height: 20px;
      }
      .position {
        font-size: 11px;
        line-height: 14px;
      }

    }
  }
}

@media screen and (max-width: 399px) {
  .personal_card {
    max-height: calc(100vw - 32px);
    .bottom_box {
      .name {
        font-size: 24px;
        line-height: 29px;
      }
      .position {
        font-size: 14px;
        line-height: 26px;
      }

    }
    .special_title {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .title {
    padding: 34px;
    .title_text {
      font-size: 19.69px;
      line-height: 27.8px;
    }
    .link {
      font-size: 19px;
      line-height: 28px;
    }
  }
}
@import "assets/styles/variables";
.cookie_manage {
  .title {
    @include SemiBold24;
    color: #2C2C2D;
  }
  .description {
    @include Regular14;
    color: #616164;
  }
  .cookie_option {
    padding: 15px 20px;
    gap: 15px;
    .cookie_label {
      @include SemiBold16;
      color: #2C2C2D;
    }
    .cookie_description {
      @include Regular14;
      color: #616164;
    }
    ul {
      margin: 0;
      padding-left: 20px;
    }
  }
  .cookie_option:not(:last-child) {
    border-bottom: 1px solid #D1D1D6;
  }
  .reject {
    @include SemiBold16;
    cursor: pointer;
  }
  .action_buttons_container {
    gap: 16px;
    margin-left: 56px;
  }
}

@media screen and (max-width: 577px){
  .cookie_manage {
    .action_buttons_container {
      margin-left: 0;
      flex-direction: column-reverse;
    }
    .cookie_option {
      padding: 12px;
    }
    .toggle_container {
      max-height: 400px;
      overflow: auto;
    }
  }

}




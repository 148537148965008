.position_details {
  padding: 70px 100px;
  max-width: 1800px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  gap: 50px;

  .left_banner {
    overflow: auto;
    flex: 0 0 46%;

    .title {
      font-family: Futura-Bold, sans-serif;
      font-size: 60px;
      line-height: 71.92px;
    }
  }

  .description {
    font-size: 18px;
    line-height: 23.44px;
  }

  .right_banner {
    height: max-content;
    border: 1px solid #140D0B;
    padding: 40px;
  }
}

@media screen and (max-width: 1440px) {
  .position_details {
    padding: 70px 60px;
  }
}

@media screen and (max-width: 1200px) {
  .position_details {
    padding: 60px 0;
    max-width: 688px;
    height: unset;
    gap: 40px;

    .left_banner {
      flex: 0 0 100%;
    }
  }
}

@media screen and (max-width: 450px) {
  .position_details {
    padding: 32px 16px;

    .left_banner {
      .title {
        font-size: 32px;
        line-height: 38.36px;
      }
    }

    .description {
      font-size: 16px;
      line-height: 20.83px;
    }

    .right_banner {
      padding: 24px;
    }
  }
}
.full_width_layout {
  width: 100%;
  //height: 100vh;
  background-color: #FFFFFF;

  .header_section {
    .home_header {
      padding: 20px 40px 19px;
      border-bottom: 1px solid #140D0B;
      align-items: center;
    }
  }

  .outlet {
    height: calc(100vh - 80px);
  }
}

.credo {
  .header_section {
    background-color: #331DAD;

    .home_header {
      border-bottom: 1px solid #FFFFFF;

    }
  }

  .button {
    svg {

      path {
        fill: #FFFFFF;
      }
    }
  }
}

.services {
  .header_section {
    background-color: #331DAD;

    .home_header {
      border-bottom: 1px solid #FFFFFF;

    }
  }

  .button {
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }
}

.careers {
  .header_section {
    background-color: #331DAD;

    .home_header {
      border-bottom: 1px solid #FFFFFF;

    }
  }

  .button {
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }
}

.scribex-modal-children-wrapper {
  margin-top: 0;
}

.nav_active {
  background-color: #15A6FF;

  .header_section {
    background-color: #15A6FF;

    .home_header {
      border-bottom: 1px solid white;
    }
  }
}

@media screen and (max-width: 768px) {
  .full_width_layout {
    .header_section {

      .home_header {
        padding: 16px 20px 15px;
      }
    }

    .outlet {
        height: calc(100vh - 72px);
      }
  }
}

@media screen and (max-width: 450px) {
  .full_width_layout {
    .outlet {
      height: calc(100dvh - 72px);
    }
  }
}

.button {
  z-index: 2;
  width: 32px;
  margin: auto 0;
  height: 32px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  svg {
    width: 26px;
    transition: transform 0.3s;
  }
}

.burger_active {
  margin: auto 0;
  align-items: center;
  display: flex;
  position: relative;

  svg {
    position: absolute;
    top: 50%;

    path {
      fill: #FFFFFF;
    }
  }

  svg:nth-child(1) {
    transform: translateY(-50%) rotate(45deg);
  }

  svg:nth-child(2) {
    display: none;
  }

  svg:nth-child(3) {
    transform: translateY(-50%) rotate(-45deg);
  }

}


$main-gray: #414141;
$light-gray: #656565;
$main-font-thin: DMSans-Thin, sans-serif;
$main-font-extra-light: DMSans-ExtraLight, sans-serif;
$main-font-regular: DMSans-Regular, sans-serif;
$main-font-semi-bold: DMSans-SemiBold, sans-serif;
$main-font-medium: DMSans-Medium, sans-serif;
$main-font-bold: DMSans-Bold, sans-serif;
$main-font-extra-bold: DMSans-ExtraBold, sans-serif;
$black: #000000;
$main-black: #121212;
$white: #ffffff;
$main-border-radius: 8px;
$primary-color: #8995FF;
$font-jost-bold: Jost-Bold, sans-serif;
$font-jost-extra-bold: Jost-ExtraBold, sans-serif;
$font-futura-bold: Futura-Bold, sans-serif;
$font-futura-medium: Futura-Medium, sans-serif;
$font-futura-light: Futura-Light, sans-serif;

@mixin Container {
  overflow: hidden;
  max-width: 1320px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: none !important;
}

@mixin SemiBold16 {
  font-family: $main-font-semi-bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  //color: $main-gray;
}

@mixin SemiBold24 {
  font-family: $main-font-semi-bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0;
}

@mixin ExtraBoldTitle {
  font-family: $main-font-extra-bold;
  font-size: 32px;
  line-height: 42px;
  color: $main-black;
  display: block;
  margin: 0;
}

@mixin ExtraBold22 {
  font-family: $main-font-extra-bold;
  font-size: 22px;
  line-height: 34px;
  letter-spacing: 0;
}

@mixin Regular16 {
  font-family: $main-font-regular;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

@mixin Regular14 {
  font-family: $main-font-regular;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

@mixin Regular12 {
  font-family: $main-font-regular;
  font-size: 12px;
  line-height: 20px;
  color: #2C2C2D;
}

@mixin InputRegular {
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 11px;
  height: 46px;
  width: 100%;
  @include Regular16
}


:export {
  futuraLight: $font-futura-light;
  futuraMedium: $font-futura-medium;
  futuraBold: $font-futura-bold;
  mainFontRegular: $main-font-regular;

}

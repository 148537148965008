.team {
  max-width: 1720px;
  margin: 0 auto;
  padding: 50px 60px 30px;
  width: 100%;
  height: max-content;

  .title_container {
    gap: 24px;

    .title {
      font-family: Futura-Bold, sans-serif;
      font-size: 80px;
      line-height: 95.9px;
      white-space: nowrap;
    }

    .dot {
      width: 12px;
      height: 12px;
      background: #15A6FF;
      display: inline-block;
      margin-left: 4px;

    }

    .description {
      font-family: Futura-Light, sans-serif;
      font-size: 20px;
      line-height: 24px;
      max-width: 394px;
      display: inline-block;
    }
  }

  .team_card_container {
  }

}

@media screen and (max-width: 1440px) {
  .team {
    padding: 70px 60px 20px;
  }
}

@media screen and (max-width: 1024px) {
  .team {
    padding: 32px 40px;

    .title_container {
      flex-wrap: wrap;
      gap: 12px;

      .title {
        font-size: 74px;
        line-height: 88px;
        white-space: pre-wrap;
      }
    }

  }
}

@media screen and (max-width: 767px) {
  .team {
    padding: 24px 16px;

    .title_container {
      .title {
        font-size: 36px;
        line-height: 43px;
        white-space: pre-wrap;
      }

      .description {
        font-size: 16px;
        line-height: 20px;
      }
    }

  }
}

@media screen and (max-width: 400px) {
  .team {
    .team_card_container {
      .card_element {
        flex: 100%;
        //height: 288px;
        height: calc(100vw - 32px);
      }
    }
  }
}
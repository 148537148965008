.position_labels {
  .label {
    padding: 0 16px;
    border-right: 1px solid #D5D5D5;
  }

  .label:first-child {
    padding-left: 0;
  }

  .label:last-child {
    border-right: none;
  }
}

@media screen and (max-width: 450px) {
  .position_labels {
    .label {
      font-size: 14px;
      line-height: 20px;

    }
  }
}
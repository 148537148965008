@import "assets/styles/variables";

.cookie {
  gap: 16px;

  .description {
    span {
      @include Regular16;
      color: #616164;
    }
  }

  .link {
    color: #2C2C2D;
    cursor: pointer;
  }
}
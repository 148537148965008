@import "variables";

.block {
  display: block;
}
.w-100 {
  width: 100%;
}
.center {
  text-align: center;
}
.margin-t-20 {
  margin-top: 20px;
}

.margin-b-30 {
  margin-bottom: 30px;
}
.container {
  @include Container;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

@import "assets/styles/variables";

.link {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  border-left: 1px solid #331DAD;


  .link_text {
    color: #331DAD;
    font-family: $font-futura-light;
    font-size: 150px;

    &:hover {
      color: white;
    }
  }
}

@media screen and (max-width: 1250px) {
  .link {

    .link_text {
      font-size: 130px;
      line-height: 153.83px;
    }
  }

}

@media screen and (max-width: 1024px) {
  .link {
    writing-mode: unset;
    transform: unset;
    border-left: unset;
    border-bottom: 1px solid #331DAD;

    .link_text {
      font-size: 100px;
      line-height: 119.87px;
    }
  }
}

@media screen and (max-width: 450px) {
  .link {
    .link_text {
      font-size: 70px;
      line-height: 83.91px;
    }
  }
}
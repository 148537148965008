.form {
  .form_title {
    font-family: Futura-Bold, sans-serif;
    font-size: 32px;
    line-height: 38.36px;
  }

  .form_fields {
    gap: 32px;

    & > div {
      flex: 1 1 0;
    }
  }

  .letter_count {
  }
}

@media screen and (max-width: 450px) {
  .form {
    .form_title {
      font-size: 20px;
      line-height: 23.97px;
    }

    .form_fields {
      gap: 24px;
    }
  }
}

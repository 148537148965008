@import "assets/styles/variables";

.contacts {
  padding: 100px 120px 60px;

  .title_container {
    display: flex;
    flex-direction: column;

    .contact_us {
      font-size: 160px;
      line-height: 172px;
      font-family: $font-futura-bold;
      color: #331DAD;
      -webkit-user-select: none;
      user-select: none;
      margin-left: -4px;
    }

    .email {
      font-size: 80px;
      line-height: 104px;
      color: #331DAD;
      -webkit-user-select: none;
      user-select: none;
    }
  }

  .address_container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 25px;
  }

  .work_schedule_container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .address {
    color: #140D0B;
    font-size: 16px;
    line-height: 20px;
    font-family: $main-font-medium;
  }

  .bottom_container {
    .phone_link {
      &:hover {
        color: #15A6FF;
      }
    }
  }

}

@media screen and (max-width: 1024px) {
  .contacts {
    padding: 183px 60px 60px 49px;

    .title_container {
      .contact_us {
        font-size: 120px;
        line-height: 124px;
      }

      .email {
        font-size: 60px;
        line-height: 78px;
      }
    }
  }

}

@media screen and (max-width: 767px) {
  .contacts {
    padding: 172px 24px 24px;

    .title_container {
      .contact_us {
        font-size: 60px;
        line-height: 71px;
      }

      .email {
        font-size: 30px;
        line-height: 39px;
      }
    }

    .address {
      font-size: 14px;
      line-height: 18px;
    }

    .phone_link {
      font-size: 14px;
      line-height: 18px;
    }

    .bottom_container {
      flex-direction: column;
      justify-content: end;
      align-items: baseline;
      gap: 8px;
    }
  }
}
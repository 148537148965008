.service {
  border-bottom: 1px solid rgba(20, 13, 11, 1);
  padding: 60px 0;

  .title_container {
    width: 46%;

    .title {
      font-size: 60px;
      line-height: 78.12px;
    }
  }

  .option_section {
    width: 50%;

    .options_container {
      :last-child {
        border-bottom: none;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .service {
    .title_container {
      width: 440px;

      .title {
        font-size: 40px;
        line-height: 52px;
      }
    }

    .description {
      font-size: 16px;
      line-height: 20.83px;
    }
  }
}

@media screen and (max-width: 900px) {
  .service {
    .title_container {
      width: 100%
    }

    .option_section {
      width: 100%;
    }
  }
}

@media screen and (max-width: 450px) {
  .service {
    padding: 40px 0;

    .title_container {
      .title {
        font-size: 32px;
        line-height: 41.66px;
      }
    }
  }
}
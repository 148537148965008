.careers {
  padding: 70px 60px;
  width: 100%;
  background-color: #331DAD;

  .title_container {
    gap: 40px;

    .title {
      font-family: Futura-Bold, sans-serif;
      font-size: 80px;
      line-height: 95.9px;
      white-space: nowrap;
    }

    .reach_out_text {
      font-family: Futura-Light, sans-serif;
      line-height: 24px;
    }
  }

  .open_positions {
    .no_job_container {
      max-width: 500px;
      margin: 0 auto;

      .open_positions_title {
        font-size: 32px;
        font-weight: 600;
        line-height: 41.66px;
      }
    }

    .open_positions_title {
      font-size: 32px;
      line-height: 41.66px;
    }

    .open_positions_container {
      max-width: 790px;
    }

  }
}


@media screen and (max-width: 1200px) {
  .careers {
    padding: 60px 40px;

    .title_container {
      gap: 16px;

      .title {
        font-size: 74px;
        line-height: 88.71px;
      }
    }
  }

}

@media screen and (max-width: 740px) {
  .careers {
    padding: 32px 24px;

    .title_container {
      gap: 16px;

      .title {
        font-size: 36px;
        line-height: 43.15px;
      }

      .reach_out_text {
        font-size: 16px;
        line-height: 19.18px;
      }
    }
  }

}

@media screen and (max-width: 550px) {
  .careers {
    .open_positions {
      .no_job_container {
        .open_positions_description {
          font-size: 16px;
          line-height: 20.83px;
        }
      }

      .open_positions_title {
        font-size: 24px;
        line-height: 31.25px;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .careers {
    .title_container {

      .title {
        font-size: 30px;
      }
    }
  }
}


.job_details {
  padding: 32px 0;
  border-bottom: 1px solid #FFFFFF4D;
}

.job_details:last-child {
  border-bottom: none;
}

@media screen and (max-width: 576px) {
  .job_details {
    padding: 24px 0;

    .button {
      margin-top: 2px;

      .apply_button {
        font-size: 16px;
        line-height: 20px;
        margin-left: auto;
      }
    }

    .title {
      font-size: 18px;
      line-height: 24px;
    }
    .job_info {
      font-size: 14px;
      line-height: 18px;
    }
  }

}